import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorService, LoggingService, NotificationService } from './core/app-services';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);
        const notifier = this.injector.get(NotificationService);
        const logger = this.injector.get(LoggingService);

        let message;
        let stackTrace = "";
        if (error instanceof HttpErrorResponse) {
            // Server error
            // Do not notify user about 401 errors, as they are handled in server-error.interceptor.ts
            if (error.status !== 401) {
                logger.trackServerError(error);
                message = errorService.getServerErrorMessage(error);
                stackTrace = errorService.getServerErrorStackTrace(error);
                notifier.showError(message);
            }
        } else {
            // Client Error
            logger.trackClientError(error);
            message = errorService.getClientErrorMessage(error);
            notifier.showError(message);
        }
        console.error(error);
    }
}
