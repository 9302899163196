import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { inAnimation } from 'src/app/core/helpers/animations';
import { DateRange } from 'src/app/core/plots/plot.models';
import { DateRangePreset } from 'src/app/shared/date-range/date-range.component';
import { AdditionalListInformation } from 'src/app/shared/station-filters/select-filters/select-filters.component';

@Component({
    selector: 'app-kpi-settings',
    template: `
        <div class="container" [@inAnimation]>
            <div class="settings-box flex-row mt-32">
                <app-station-filters
                    [view]="'kpi'"
                    [additionalFilterInformation]="additionalInformation"
                ></app-station-filters>
                <app-date-range
                    [dateRange]="dateRange"
                    [dateRangePreset]="presetDateRanges"
                    [maxDate]="getCurrentDate()"
                    size="small"
                    type="date"
                    (dateRangeChange)="onDaterangeChange.emit($event)"
                ></app-date-range>
            </div>
        </div>
    `,
    styleUrls: ['./kpi-settings.component.scss'],
    animations: [
        trigger('inAnimation', [
            transition(':enter', [
                useAnimation(inAnimation)
            ])
        ])
    ]
})
export class KpiSettingsComponent implements OnInit {
    // optionally init with preset date range (iso strings)
    @Input('dateRange') presetDateRange?: DateRange | null;
    // emitted when full daterange is set, returns object of dates
    @Output('onDaterangeChange') onDaterangeChange = new EventEmitter<{ from: Date, until: Date }>();
    // selected Date Range
    dateRange!: DateRange;
    // date range set for select options
    presetDateRanges: DateRangePreset[] = [
        {
            label: '7d',
            interval: 7,
            range: 'd'
        },
        {
            label: '14d',
            interval: 14,
            range: 'd'
        },
        {
            label: '30d',
            interval: 30,
            range: 'd'
        },
        {
            label: '90d',
            interval: 90,
            range: 'd'
        }
    ];
    additionalInformation: AdditionalListInformation[] = [
        // add information to be displayed for certain values here
        //
        // currently no info is being displayed
    ];
    
    constructor() {}

    ngOnInit() {
        // daterange - set until value to now
        if (this.presetDateRange) {
            if (typeof (this.presetDateRange.from) === 'string' && typeof (this.presetDateRange.until) === 'string') {
                let dateFrom = new Date(this.presetDateRange.from),
                    dateUntil = new Date();

                this.dateRange = { from: dateFrom, until: dateUntil };
            } else {
                this.dateRange = { from: this.presetDateRange.from, until: new Date() }
            }
        } else {
            let today = new Date(),
                sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
            this.dateRange = { from: sevenDaysAgo, until: today }
        }
    }

    getCurrentDate(): Date {
        return new Date();
    }

}
