import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from './shared/shared.module';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from 'src/environments/environment';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {GlobalErrorHandler} from './global-error-handler';
import {ServerErrorInterceptor} from './server-error.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LoginScreenComponent } from './shared/login-screen/login-screen.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverviewModule } from './overview/overview.module';
import { AuthApiModule } from './core/auth-backend/auth-api.module';
import { DataApiModule } from './core/data-backend/data-api.module';
import { MapViewModule } from './map-view/map-view.module';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import de from '@angular/common/locales/de';
import { de_DE, en_US, NZ_DATE_LOCALE, NZ_I18N, NzI18nService } from 'ng-zorro-antd/i18n';
import { NotFoundComponent } from './not-found/not-found.component';
import { GlobalModalsComponent } from './global-modals/global-modals.component';
import { KpiViewComponent } from './kpi-view/kpi-view.component';
import { KpiPlotsComponent } from './kpi-view/kpi-plots/kpi-plots.component';
import { KpiSettingsComponent } from './kpi-view/kpi-settings/kpi-settings.component';
import { KpiDataBarComponent } from './kpi-view/kpi-data-bar/kpi-data-bar.component';
import { CoreModule } from './core/core.module';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { FiltersetsModule } from "./filtersets/filtersets.module";
import { MeterValueEstimatorComponent } from "./shared/meter-value-estimator/meter-value-estimator.component";
import { enUS } from "date-fns/locale";
import { register } from 'swiper/element/bundle';
import { PrivateModalsComponent } from "./private-modals/private-modals.component";
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { tap } from "rxjs";
import { StaticTranslationLoader } from "./core/helpers/static-translations-loader";

registerLocaleData(en);
registerLocaleData(de);
register();

export const storageFactory = (): OAuthStorage => localStorage;


@NgModule({
    declarations: [	
        AppComponent,
        LoginScreenComponent,
        NotFoundComponent,
        GlobalModalsComponent,
        PrivateModalsComponent,
        KpiViewComponent,
        KpiPlotsComponent,
        KpiSettingsComponent,
        KpiDataBarComponent
   ],
    imports: [
        BrowserModule,
        CoreModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
                customUrlValidation(url) {
                    // only match urls starting with http(s)://
                    // this will filter out any relative urls e.g. for assets
                    const urlRegEx = /(http(s?)):\/\//i;
                    if (!urlRegEx.test(url)) return false;
                    // only append id token to calls to our backends
                    const thisURL   = new URL(url);
                    const windowURL = new URL(window.location.href);
                    return thisURL.hostname == windowURL.hostname
                },
            }
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useClass: StaticTranslationLoader
            }
        }),
        OverviewModule,
        MapViewModule,
        SharedModule,
        FiltersetsModule,
        AppRoutingModule,
        AuthApiModule.forRoot({rootUrl: environment.authUrl}),
        DataApiModule,
        BrowserAnimationsModule,
        DragDropModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center',
            preventDuplicates: true
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        MeterValueEstimatorComponent
    ],
    providers: [
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: NZ_DATE_LOCALE, useValue: enUS },
        { provide: NZ_I18N, useValue: en_US },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    // prepared i18n language switch for datepicker:
    // https://ng.ant.design/docs/i18n/en#how-to-format-a-date-using-date-fns
    constructor(
        private _i18n: NzI18nService,
        private _translate: TranslateService
    ) {
        this._translate.onLangChange.pipe(
            takeUntilDestroyed(),
            tap((langChanged) => {
                if (langChanged.lang == 'de') {
                    this._i18n.setLocale(de_DE);
                    // @ts-ignore
                    window.Plotly.setPlotConfig({locale: 'de'})
                } else {
                    this._i18n.setLocale(en_US);
                    // @ts-ignore
                    window.Plotly.setPlotConfig({locale: 'en-US'})
                }
            })
        ).subscribe()
    }
}
