import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapViewComponent } from './map-view.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverviewModule } from '../overview/overview.module';
import { CoreModule } from '../core/core.module';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        OverviewModule,
        AppRoutingModule,
        BrowserAnimationsModule
    ],
    declarations: [
        MapViewComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MapViewModule { }
