import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StationColumn, overviewRepository } from 'src/app/core/stores/overview.repository';
import { distinctUntilChanged, map, Observable, switchMap } from 'rxjs';
import { detailsRepository } from 'src/app/core/stores/details.repository';
import { ExtendedChargingStation } from 'src/app/core/helpers/transform-stations.helper';

@Component({
    selector: 'app-charging-stations-overview',
    template: `
        <div 
            class="overview-container container pt-16 pb-32"
            [class.is-fullscreen]="fullscreen"
        >
            <app-table-overview 
                *ngIf="this.tableColumns$ | async as tableColumns"
                [tableColumns]="tableColumns"
                [tableContents]="tableContents"
                [expandedRows]="repo.allExpandedRows$ | async"
                [pagination]="pagination"
                [maxPage]="maxPage"
                [lastStationId]="detailsRepository.lastStationId$ | async"
                (fullscreen)="fullscreen = $event"
                (columnSort)="reorderColumns($event)"
            ></app-table-overview>
        </div>
    `,
    styleUrls: ['./charging-stations-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChargingStationsOverviewComponent {
    @Input('tableContents') tableContents: Array<ExtendedChargingStation> | null = [];
    // current page
    @Input('pagination') pagination!: number | null;
    // maxPage of pagination
    @Input('maxPage') maxPage!: number;
    // whether fullscreen should be active
    fullscreen: boolean = false;
    // map all columns with selected ids from store
    tableColumns$: Observable<StationColumn[]> = this.repo.selectedColumns$.pipe(
        distinctUntilChanged(),
        switchMap(selColumns => {
            return this.repo.allColumns$.pipe(
                map(allColumns => {
                    let selectedFullColumns: StationColumn[] = [];
                    selColumns.forEach(selColumn => {
                        let column = allColumns.find(fullCol => fullCol.id == selColumn.id)
                        if (column) {
                            selectedFullColumns.push(column)
                        }
                    })
                    return selectedFullColumns
                })
            )
        })
    )

    constructor(
        public repo: overviewRepository,
        public detailsRepository: detailsRepository
    ) { }

    reorderColumns(event: StationColumn['id'][]) {
        this.repo.updateColumnOrder(event)
    }
}
