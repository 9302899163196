import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, switchMap, take, tap } from 'rxjs';
import { Notification, Tenant } from 'src/app/core/data-backend/models';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotificationsMaskComponent } from '../notifications-mask/notifications-mask.component';
import { AsyncPipe } from '@angular/common';
import { NotificationService } from 'src/app/core/app-services';
import { NotificationDataService } from 'src/app/core/data-backend/data-services';
import { NotificationForm } from 'src/app/core/stores/notifications.repository';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { decodeString } from 'src/app/core/helpers/utils.helper';

@Component({
    selector: 'app-notifications-modal',
    standalone: true,
    imports: [
        SharedModule,
        AsyncPipe,
        NotificationsMaskComponent,
    ],
    template: `
        <app-modal
            [open]="open"
            [width]="'large'"
            [noBodyPadding]="true"
            (openChange)="openChange.emit($event)"
        >
            <div header>
                <p class="headline">{{ 'NOTIFICATIONS_VIEW.EDIT_NOTIFICATION' | translate }}</p>
            </div>
            <div body class="wrapper">
                <app-notifications-mask
                    [mode]="'update'"
                    [form]="form"
                    [userTenants]="userTenants"
                    [otherTenants]="otherTenants"
                    [state]="state$ | async"
                    (onCancel)="openChange.emit(false)"
                    (submitNotification)="updateNotification($event)"
                ></app-notifications-mask>
            </div>
        </app-modal>
    `,
    styleUrl: './notifications-modal.component.scss'
})
export class NotificationsModalComponent {

    public notification$ = new BehaviorSubject<Notification | null>(null);
    
    @Input('notification') set notification(notification: Notification | null) {
        if (notification == null) return;
        this.notification$.next(notification);
    };

    @Input() userTenants: Tenant[] | null = null;
    @Input() otherTenants: Tenant[] | null = null;

    public state$ = new BehaviorSubject<'loading' | 'error' | 'success' | null>(null);
    @Input('state') set state(state: 'loading' | 'error' | 'success' | null) {
        this.state$.next(state);
    }

    @Input() public open: boolean = false;

    @Output() openChange = new EventEmitter<boolean>();

    @Output('getNotificationData') getNotificationData = new EventEmitter<void>();

    public form: NotificationForm | null = null;

    constructor(
        public notificationDataService: NotificationDataService,
        public notificationService: NotificationService
    ) {
        // set form based on notification
        this.notification$.pipe(
            takeUntilDestroyed(),
            tap((notification: Notification | null) => {
                if (notification == null) return;
                this.form = {
                    title: decodeString(notification.title),
                    description: decodeString(notification.description),
                    category: notification.category,
                    from: new Date(notification.from_date),
                    to: notification.to_date ? new Date(notification.to_date) : undefined,
                    now: false,
                    unlimited: notification.to_date ? false : true,
                    persistent: notification.persistent,
                    active: notification.active,
                    tenants: notification.tenants
                } as NotificationForm;
            })
        ).subscribe();
    }

    updateNotification(notification: Notification) {
        this.notification$.pipe(
            take(1),
            switchMap((old_notification: Notification | null) => {
                if (!old_notification) return [];
                notification.notificationId = old_notification.notificationId;
                return this.notificationDataService.updateNotifications({
                    body: { notifications: [notification] }
                })
            })
        ).subscribe({
            error: (_) => {
                this.notificationService.showError(`Unable to update notification`);
            },
            next: (_) => {
                this.notificationService.showSuccess(`Notification successfully updated`);
                // close modal
                this.openChange.emit(false);
                // fetch data
                this.getNotificationData.emit();
            }
        });
    }
}
