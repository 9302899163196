import { CommonModule, DecimalPipe, PercentPipe } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "../app-routing.module";
import { SharedModule } from "../shared/shared.module";
import { ChargingStationsOverviewComponent } from "./charging-stations-overview/charging-stations-overview.component";
import { OverviewPlotsComponent } from "./overview-plots/overview-plots.component";
import { OverviewComponent } from "./overview.component";
import { TableOverviewComponent } from "./table-overview/table-overview.component";
import { CoreModule } from "../core/core.module";
import { OverviewAlertsComponent } from "./overview-alerts/overview-alerts.component";
import { ReactiveFormsModule } from "@angular/forms";
import { OverviewNotificationsComponent } from "./overview-notifications/overview-notifications.component";
import { OverviewBulkActionsComponent } from "./overview-bulk-actions/overview-bulk-actions.component";

@NgModule({
    declarations: [
        TableOverviewComponent,
        ChargingStationsOverviewComponent,
        OverviewComponent,
        OverviewPlotsComponent,
        OverviewAlertsComponent,
        OverviewNotificationsComponent,
        OverviewBulkActionsComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OverviewModule {}
