import { Injectable } from "@angular/core";
import { createStore, select, setProp, withProps } from "@ngneat/elf";

export type NotificationForm = {
    title: string,
    description: string,
    category: 'information' | 'warning' | 'error',
    from: Date,
    to: Date | undefined,
    now: boolean,
    unlimited: boolean,
    persistent: boolean,
    active: boolean,
    tenants: string[]
}

const manageNotificationsStore = createStore(
    { name: 'form'},
    withProps<{
        search: string,
        activeOnly: boolean,
        collapsed: boolean
    }>({
        search: '',
        activeOnly: false,
        collapsed: false
    })
);

@Injectable({ providedIn: 'root' })
export class notificationsRepository {

    // free text search query
    search$ = manageNotificationsStore.pipe(
        select((state) => state.search)
    )

    // 'active only' button
    activeOnly$ = manageNotificationsStore.pipe(
        select((state) => state.activeOnly)
    )

    // whether panel is collapsed
    collapsed$ = manageNotificationsStore.pipe(
        select((state) => state.collapsed)
    )
    
    // update search query term
    updateSearch(text: string) {
        manageNotificationsStore.update(setProp('search', text))
    }

    // update active only button
    updateActiveOnly(active: boolean) {
        manageNotificationsStore.update(setProp('activeOnly', active))
    }

    // set the state of the panel collapsible
    setCollapsed(collapsed: boolean) {
        manageNotificationsStore.update(setProp('collapsed', collapsed))
    }
}
