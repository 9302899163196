import { Inject, Injectable, OnDestroy, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { EMPTY, Subject, catchError, switchMap, takeUntil, tap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AppUpdateService implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    public updateAppModalOpen = signal(false);

    constructor(
        private _updates: SwUpdate,
        private _toastr: ToastrService,
        @Inject(DOCUMENT) private _document: Document,
        private _translate: TranslateService
    ) {
        this._init();
    }

    private _init(): void {
        // Allow the app to stabilize first, before starting
        const checkUpdatesTimer$ = timer(10 * 1000, environment.appUpdateCheckInterval);

        let activeToast: ActiveToast<any> | undefined;

        // check for updates, notify user
        checkUpdatesTimer$.pipe(
            takeUntil(this._destroying$),
            switchMap(() => this._updates.checkForUpdate()),
            catchError(() => EMPTY),
            tap((updateFound) => {
                if (updateFound) {
                    // update available
                    activeToast?.toastRef.close()
                    activeToast = this._toastr.success(
                        this._translate.instant('UPDATE_INFO.TITLE', {brandName: environment.brandName}),
                        this._translate.instant('UPDATE_INFO.UPDATE_FOUND')
                    )
                    // show update context
                    this.updateAppModalOpen.set(true);
                }
            })
        ).subscribe();

        // Fallback if activateUpdate() leads to an version mismatch
        // Should not happen, as activateUpdate() is followed by a reload, but just in case
        this._updates.unrecoverable.pipe(
            takeUntil(this._destroying$),
            tap((event) => {
                activeToast?.toastRef.close();
                activeToast = this._toastr.error(
                    this._translate.instant('UPDATE_INFO.UPDATE_FAILED.MESSAGE', {errorInfo: event.reason}),
                    this._translate.instant('UPDATE_INFO.UPDATE_FAILED.TITLE'),
                    {
                        progressBar: true,
                        progressAnimation: 'decreasing',
                        timeOut: 3000
                    }
                );

                setTimeout(() => {
                    this._document.location.reload();
                }, 3000);
            })
        ).subscribe()
    }

    public applyUpdate() {
        // Activate the update - simply reloading would also work, just making sure sure sure it's activated
        this._updates.activateUpdate().then(() => {
            // Reload the page to apply the update
            this._document.location.reload();
        });
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
