import { formatDate, formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, pairwise, skip, startWith, take, tap, withLatestFrom } from 'rxjs';
import { ContextMenuDirective } from 'src/app/core/directives/contextMenu.directive';
import { ChargedAmount, NumSessions, StationLocations } from 'src/app/core/data-backend/models';
import { overviewRepository } from 'src/app/core/stores/overview.repository';
import { StateColors, StateHelperService } from 'src/app/core/helpers/state-helper.service';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwiperContainer } from 'swiper/element';
import { stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedStateNameService } from 'src/app/core/app-services/localized-state-name.service';

export type lastOverallState = "No Data" | "Ok" | "To Be Monitored" | "Potential Failure" | "Failure";
export type OverviewPlot = {id: string, data: any[], layout: any, config: {}, show: boolean, polling: boolean, onClick?: any};
@Component({
  selector: 'app-overview-plots',
  template: `
    @if (collapsibleVM$ | async; as collapsibleVM) {
        <evc-collapsible 
            [collapsed]="collapsibleVM.collapsed"
            [loading]="collapsibleVM.loading"
            [bodyClasses]="['pl-0 pb-0 pr-0']"
            (collapsedChange)="repo.setPlotsCollapsed($event)"
        >
            <ng-container header>
                <p class="collapsible-title">
                    {{ 'DASHBOARD.GRAPHS.TITLE' | translate }}
                </p>
            </ng-container>
            <ng-container body>
                <div class="main-wrapper">
                    <div class="legend">
                        <div *ngFor="let bullet of this.lastOverallStates; let i = index">
                            <span style="background-color: {{ this.overallStateColors[i] }}"></span>
                            <p>{{ bullet | localizedStateName | async }}</p>
                        </div>
                    </div>
                    @if (mapVM$ | async; as mapVM) {
                        <div 
                            class="content-grid"
                            [class.map-expand]="mapVM.mapExpanded"
                        >
                            <div class="map-wrapper flex-shrink-0">
                                <div class="map">
                                    <app-stations-map
                                        [isVisible]="!collapsibleVM.collapsed"
                                        [isLoading]="mapVM.mapPolling"
                                        [interactionMode]="'popup-simple'"
                                        [stationLocations]="{stationLocations: mapVM.data, isAutoUpdate: mapVM.isAutoUpdate}"
                                        [mapSettings]="{
                                            padding: {
                                                top: 0.5,
                                                right: 0.5,
                                                bottom: 1,
                                                left: 0.5
                                            },
                                            hideIconsHeight: null,
                                            logoPosition: 'top-left',
                                            fitBoundsOnce: false
                                        }"
                                        [fitAnimation]="'none'"
                                        [selectedStations]="repo.allbulkActionRowIDs$ | async"
                                        [emitBounds]="true"
                                        [initalBounds]="repo.stationsMapBounds$ | async"
                                        (boundsChange)="repo.setStationMapBounds($event)"
                                    >
                                    </app-stations-map>
                                </div>
                                <div class="map-buttons-wrapper">
                                    <!-- no coords -->
                                    <div
                                        class="map-btn no-coords"
                                        [tooltip]="'DASHBOARD.GRAPHS.NO_COORDS_TOOLTIP' | translate"
                                        [size]="'small'"
                                        [textAlign]="'left'"
                                        [width]="150"
                                    >
                                        <div class="icon-wrapper">
                                            <span class="material-icon">location_disabled</span>
                                        </div> {{ mapVM.noCoords | number }}
                                    </div>
                                    <div class="spacing"></div>
                                    <!-- sync  -->
                                    <button
                                        class="map-btn sync"
                                        type="button"
                                        [class.active]="mapVM.mapTableSync"
                                        (click)="repo.toggleMapTableSync()"
                                    >
                                        <div class="icon-wrapper">
                                            <span class="material-icon">{{ mapVM.mapTableSync ? 'sync' : 'sync_disabled' }}</span>
                                        </div>
                                        <span class="title">
                                            @if (mapVM.mapTableSync) {
                                                {{ 'DASHBOARD.TABLE_SYNC.ON' | translate }}
                                            } @else {
                                                {{ 'DASHBOARD.TABLE_SYNC.OFF' | translate }}
                                            }
                                        </span>
                                    </button>
                                    <!-- expand map button -->
                                    <button
                                        class="map-btn"
                                        type="button"
                                        [class.active]="mapVM.mapExpanded"
                                        [tooltip]="(mapVM.mapExpanded ? 'DASHBOARD.MINIFY_MAP' : 'DASHBOARD.EXPAND_MAP' )| translate"
                                        [size]="'small'"
                                        (click)="repo.toggleMapExpanded()"
                                    >
                                        <div class="icon-wrapper">
                                            <span class="material-icon">{{ mapVM.mapExpanded ? 'fullscreen_exit' : 'fullscreen' }}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="swiper-wrapper">
                                <swiper-container
                                    init="false"
                                    #swiperRef
                                >
                                    <ng-container *ngFor="let plot of overviewPlots">
                                        <swiper-slide>
                                            <div class="w-100 flex-row align-items-center justify-content-center">
                                                <plotly-plot
                                                    [class.loading]="plot.polling"
                                                    [data]="plot.data"
                                                    [layout]="plot.layout"
                                                    [config]="plot.config"
                                                    [updateOnDataChange]="true"
                                                    [style]="{display: 'flex', alignItems: 'center', justifyContent: 'center'}"
                                                    [useResizeHandler]="false"
                                                    (plotlyClick)="plot.onClick ? plot.onClick($event) : null"
                                                    (hover)="customTooltip($event)"
                                                    (unhover)="removeTooltip()"
                                                ></plotly-plot>
                                                <ng-template *ngIf="!plot.polling; else loadingCircle"></ng-template>
                                            </div>
                                        </swiper-slide>
                                    </ng-container>
                                </swiper-container>
                            </div>
                        </div>
                    }
                </div>
            </ng-container>
            <ng-template #loadingCircle>
                <div class="preloader-container">
                    <app-preloader
                        color="darkgrey"
                    ></app-preloader>
                </div>
            </ng-template>
            <ng-template #loadingCircleMap>
                <div class="preloader-container map">
                    <app-preloader
                        color="darkgrey"
                    ></app-preloader>
                </div>
            </ng-template>
        </evc-collapsible>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./overview-plots.component.scss']
})
export class OverviewPlotsComponent {
    // helper to call directive
    @ViewChildren(ContextMenuDirective) directives: any;
    private _swiperContainer: SwiperContainer | undefined;
    private _swiperRef$ = new BehaviorSubject<ElementRef | null>(null);
    @ViewChild('swiperRef') set swiperRef(swiperRef: ElementRef) {
        if (!swiperRef) return;
        this._swiperRef$.next(swiperRef)
    };
    // interpretations
    stateColors: StateColors | undefined;
    lastOverallStates: lastOverallState[] = ['No Data', 'Ok', 'To Be Monitored', 'Potential Failure', 'Failure'];
    overallStateColors: string[] = [];
    // data for stations map
    stationMapData: StationLocations[] | null = null;
    // Plots in Overview collapsible
    overviewPlots: OverviewPlot[] = [];
    // Default graph options
    defaultPlotOptions = {
        layout: {
            height: 300,
            width: 300,
            margin: { t: 25, b: 55, l: 25, r: 25 },
            colorway: [] as any[],
            font: {
                family: '"ChesnaGrotesk", Arial, Geneva, Helvetica, sans-serif'
            },
            transition: {
                duration: 200,
                easing: 'cubic',
                ordering: 'traces first'
            },
        },
        config: {
            displayModeBar: false,
        },
    };
    defaultInterval = 14;
    // default range for type date axes [from, until]
    defaultDateRange: Date[] = []
    // custom tooltip in plot
    private myPopup!: any;

    public collapsibleVM$: Observable<{
        collapsed: boolean,
        loading: boolean
    }>;

    public mapVM$: Observable<{
        mapTableSync: boolean,
        mapExpanded: boolean,
        data: StationLocations[],
        noCoords: number,
        isAutoUpdate: boolean,
        mapPolling: boolean
    }>;

    constructor(
        public repo: overviewRepository,
        public filtersRepo: stationFiltersRepository,
        public ngZone: NgZone,
        public translate: TranslateService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _permService: PermissionsService,
        private _localizedStateName: LocalizedStateNameService
    ) {
        let fromDate = new Date();
        // set default date range for plots
        // add one to defaultInterval because bars will be placed on 00:00 and would be cut off
        fromDate.setDate(fromDate.getDate() - (this.defaultInterval + 1))

        this.defaultDateRange = [
            fromDate,
            new Date()
        ]

        // init swiper with layout based on mapExpanded
        this._swiperRef$.pipe(
            skip(1), // skips initial subject value of null
            take(1),
            withLatestFrom(this.repo.mapExpanded$),
            tap(([swiperRef, mapExpanded]) => {
                if (!swiperRef) return
                this._swiperContainer = swiperRef.nativeElement as SwiperContainer;
                this._initSwiper(mapExpanded)
            })
        ).subscribe()

        // get plot data from service, combine with req state for UI states
        combineLatest({
            ovPlots: this.repo.overviewPlotsData$,
            stationLocations: this.repo.stationLocations$,
            newLang: this.translate.onLangChange.pipe(startWith(null))
        }).pipe(
            debounceTime(50),
            takeUntilDestroyed(),
            tap((res) => {
                this._setColors()
                if (!res.ovPlots.isLoading) {
                    this.updateNumOfSessionsPlot(res.ovPlots.data.numSessions);
                    this.stationMapData = res.stationLocations.data.stationLocations;
                    this.updateChargePointPlot(res.stationLocations.data.stationLocations);
                    this.updateChargedAmountPlot(res.ovPlots.data.chargedAmount);
                }
                // manual change detection
                setTimeout(() => {
                    this.ngZone.run(() => {
                        this._changeDetectorRef.detectChanges()
                        // fixes layout in edge cases, where plots are loaded just right after swiper checked it's layout
                        if (this._swiperContainer) this._swiperContainer.swiper.update()
                    })
                }, 1)
            })
        ).subscribe()

        // handles collapsible state, adds polling flag to plots
        this.collapsibleVM$ = combineLatest({
            collapsed: this.repo.plotsCollapsed$.pipe(
                // disable mapTableSync when plots are collapsed while in mode
                withLatestFrom(this.repo.mapTableSync$),
                map(([plotsCollapsed, mapTableSync]) => {
                    if (plotsCollapsed && mapTableSync) this.repo.toggleMapTableSync()
                    return plotsCollapsed
                })
            ),
            loading:  this.repo.overviewPlotsData$.pipe(
                map(({isLoading}) => isLoading),
                tap((isLoading) => {
                    if (isLoading) this.overviewPlots.forEach((plot) => plot.polling = isLoading)
                })
            )
        })

        // handles filters for sync, returns mapTableSync boolean to simplify subscription (in mapVM$)
        const mapSyncHandler$ = combineLatest({
            mapTableSync: this.repo.mapTableSync$.pipe(
                startWith(false),
                pairwise(),
                map(([prev, current]) => {
                    const lngLatFilters = ['latitude', 'longitude'];
                    // except ("hide") lat and long filters during sync with map
                    this.filtersRepo.setFilterExceptions(current ? lngLatFilters : []);
                    // sync is disabled
                    if (prev && !current) {
                        // delete lat and lng filters
                        this.filtersRepo.deleteFilters(lngLatFilters)
                    }

                    return current
                })
            ),
            mapBounds: this.repo.stationsMapBounds$.pipe(
                debounceTime(10)
            )
        }).pipe(
            tap(({mapTableSync, mapBounds}) => {
                // set lat lng filters while mapTableSync is active
                if (mapTableSync && mapBounds) {
                    const { lat, lng } = mapBounds;
                    this._updateFilter('latitude', lat);
                    this._updateFilter('longitude', lng);
                }
            }),
            map(({mapTableSync}) => mapTableSync)
        )

        // set value to VM wrapper
        this.mapVM$ = combineLatest({
            mapTableSync: mapSyncHandler$,
            mapExpanded: this.repo.mapExpanded$.pipe(
                // handles layout changes for plots in swiper
                startWith(false),
                pairwise(),
                map(([prev, current]) => {
                    if (prev && !current) {
                        // reset plot swiper
                        if (this._swiperContainer) {
                            this._swiperContainer.grid = {rows: 1};
                        }
                    } else if (!prev && current) {
                        // set stacked layout
                        if (this._swiperContainer) {
                            this._swiperContainer.grid = {rows: 2};
                        }
                    }

                    return current
                })
            ),
            stationLocations: this.repo.stationLocations$,
            stationLocationsForSync: this.repo.stationLocationsForSync$
        }).pipe(
            map(({mapTableSync, mapExpanded, stationLocations, stationLocationsForSync}) => {
                const data = mapTableSync ? stationLocationsForSync.data : stationLocations.data.stationLocations;
                const mapPolling = mapTableSync ? stationLocationsForSync.isLoading : stationLocations.isLoading;
                const noCoords = data.filter((station) => !station.latitude || !station.longitude).length;
                
                return {
                    isAutoUpdate: mapTableSync,
                    mapPolling,
                    data,
                    noCoords,
                    mapTableSync,
                    mapExpanded
                }
            })
        )
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this.translate.instant(path, interpolateParams)
    }

    private _setColors() {
        this.stateColors = new StateHelperService().getStateColors();
        this.overallStateColors = [ 
            this.stateColors.noData,
            this.stateColors.ok, 
            this.stateColors.toBeMonitored, 
            this.stateColors.potentialFailure, 
            this.stateColors.failure,
        ];

        this.defaultPlotOptions.layout.colorway = [
            this.stateColors.ok, 
            this.stateColors.toBeMonitored, 
            this.stateColors.potentialFailure, 
            this.stateColors.failure,
            this.stateColors.noData,
        ]
    }

    private _initSwiper(mapExpanded: boolean) {
        if (!this._swiperContainer) return;

        Object.assign(this._swiperContainer, {
            speed: 400,
            spaceBetween: 15,
            allowTouchMove: false,
            pagination: {
                type: 'bullets',
                clickable: true
            },
            slidesPerView: 'auto',
            grid: {
                rows: mapExpanded ? 2 : 1
            },
            injectStyles: [`
                .swiper {
                    padding-bottom: 32px;    
                }

                .swiper-pagination {
                    bottom: 8px;
                }
                
                .swiper-pagination .swiper-pagination-bullet {
                    height: 12px;
                    width: 32px;
                    border-radius: 4px;
                    background: #C4C4C4;
                }
                
                .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background: #8B71D2;
                }
                
                .swiper-pagination-bullet.swiper-pagination-bullet-active-prev,
                .swiper-pagination-bullet.swiper-pagination-bullet-active-next,
                .swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev,
                .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next {
                    transform: scale(1);
                }
            `]
        })

        this._swiperContainer.initialize()
    }

    /**
     * The function `_updateFilter` updates the value of a filter if it exists, otherwise it adds a new
     * filter with the specified key and value.
     * @param {string} key - A string representing the key of the filter to be updated or added.
     * @param {any} value - The value parameter is the new value that you want to update or add to the
     * filter.
     */
    private _updateFilter(key: string, value: any) {
        if (this.filtersRepo.hasFilter(key)) {
            this.filtersRepo.updateFilterValue(key, value)
        } else {
            this.filtersRepo.addFilter(key, value)
        }
    }
    
    // adds active filter based on selection in plot
    // callable with attribute to filter and scope of where to get the filterValue from the event
    setFilterOnClick(event: any, filterAttr: string, scope: string) {
        let value = event['points'][0][scope];
        if (value === undefined || (typeof(value) !== 'string' && typeof(value) !== 'number')) return

        if (this.filtersRepo.hasFilter(filterAttr)) {
            let currentFilter = this.filtersRepo.getFilterValue(filterAttr);
            // update if set filter doesnt match new
            if (
                currentFilter && 
                currentFilter.value && 
                currentFilter.value.length === 1 && 
                currentFilter.value[0] !== value
            ) {
                this.filtersRepo.updateFilterValue(filterAttr, [value])
            } else {
                this.filtersRepo.deleteFilters(filterAttr)
            }
        } else {
            this.filtersRepo.addFilter(filterAttr, [value])
        }
    }

    // N U M B E R  O F  S E S S I O N S

    // set/update plotData for number of sessions
    updateNumOfSessionsPlot(data: NumSessions[]) {
        // find plot in Array
        let plotId  = 'numOfSessionsPlot',
            plot    = this.overviewPlots.find(plot => plot.id === plotId),
            plotX   = data.map(entry => entry.date),
            traces  = [];

        if (!this.stateColors) this._setColors()

        // trace for type "regular" and "in_progress"
        traces.push({
            x: plotX,
            y: data.map(entry => entry.regular + (entry.in_progress ?? 0)),
            type: 'bar',
            name: this._t('DASHBOARD.GRAPHS.SUCCESSFUL'),
            hoverinfo: 'none',
            marker: {
                color: this.stateColors!.ok
            }
        })

        // trace for "unsuccessful" (types "short" and "zero")
        let failedData = data.map(entry => {
            let short   = entry.short || 0,
                zero    = entry.zero || 0
            return short + zero
        })

        traces.push({
            x: plotX,
            y: failedData,
            type: 'bar',
            name: this._t('DASHBOARD.GRAPHS.UNSUCCESSFUL'),
            hoverinfo: 'none',
            marker: {
                color: this.stateColors!.failure
            }
        })
        
        // if plot exists, update data
        if (plot) {
            // update autorange to fix animation
            plot.layout.yaxis.autorange = false
            plot.data       = traces
            plot.show       = plotX.length > 0
            plot.polling    = false
            plot.layout.yaxis.autorange = true
            // update title
            plot.layout.title.text = this._t('DASHBOARD.GRAPHS.SESSIONS')
        } else {
            // create plot
            let numOfSessionsPlot = {
                id: plotId,
                data: traces,
                show: plotX.length > 0,
                polling: false,
                layout: {
                    ...this.defaultPlotOptions.layout,
                    barmode: 'stack',
                    title: {
                        text: this._t('DASHBOARD.GRAPHS.SESSIONS'),
                        y: 0.02,
                    },
                    margin: {b: 55, l: 35, r: 25, t: 25},
                    showlegend: false,
                    bargap: 0.2,
                    xaxis: {
                        type: 'date',
                        range: this.defaultDateRange,
                        tickformat: '%b %d',
                        dtick: 86400000,
                        tickmode: 'auto',
                        nticks: 4,
                        fixedrange: true
                    },
                    yaxis: {
                        autorange: true,
                        fixedrange: true,
                        ticksuffix: ' '
                    }
                },
                config: {
                    ...this.defaultPlotOptions.config,
                }
            };

            this.overviewPlots.push(numOfSessionsPlot)
        }
    }

    // C H A R G E D  A M O U N T
    updateChargedAmountPlot(data: ChargedAmount[]) {
        // find plot in Array
        let plotId  = 'chargedAmountPlot',
            plot    = this.overviewPlots.find(plot => plot.id === plotId),
            plotX   = data.map(entry => entry.date),
            plotY   = data.map(entry => entry.charged_amount),
            trace   = [{
                x: plotX,
                y: plotY,
                type: 'bar',
                hoverinfo: 'none'
            }];

        if (plot) {
            // update autorange to fix animation
            plot.layout.yaxis.autorange = false
            plot.data       = trace;
            plot.show       = plotX.length > 0;
            plot.polling    = false;
            plot.layout.yaxis.autorange = true
            // update title
            plot.layout.title.text = this._t('DASHBOARD.GRAPHS.CHARGED_AMOUNT')
        } else {
            // Charged Amount Plot
            let chargedAmountPlot = {
                id: plotId,
                data: trace,
                show: plotX.length > 0,
                polling: false,
                layout: {
                    ...this.defaultPlotOptions.layout,
                    title: {
                        text: this._t('DASHBOARD.GRAPHS.CHARGED_AMOUNT'),
                        y: 0.02,
                    },
                    showlegend: false,
                    bargap: 0.2,
                    margin: {b: 55, l: 57, r: 25, t: 25},
                    yaxis: {
                        exponentformat: 'B',
                        ticksuffix: 'Wh ',
                        autorange: true,
                        fixedrange: true
                    },
                    xaxis: {
                        type: 'date',
                        range: this.defaultDateRange,
                        tickformat: '%b %d',
                        dtick: 86400000,
                        tickmode: 'auto',
                        nticks: 4,
                        fixedrange: true
                    }
                },
                config: {
                    ...this.defaultPlotOptions.config,
                },
            };

            this.overviewPlots.push(chargedAmountPlot);
        }
    }

    // C H A R G E  P O I N T
    updateChargePointPlot(data: StationLocations[]) {
        let plotId      = 'chargePointPlot',
            plot        = this.overviewPlots.find(plot => plot.id === plotId),
            plotData: {[key in lastOverallState]: number} = {
                'Ok': 0,
                'To Be Monitored': 0,
                'Potential Failure': 0,
                'Failure': 0,
                'No Data': 0
            }

        // find 'worst' state of entrys lastOverallStates
        data.forEach(entry => {
            let worstState = this.getLowestOverallState(entry.lastOverallState);
            plotData[worstState]++
        })

        let barTrace =  [{
                            type: "bar",
                            y: Object.values(plotData),
                            x: Object.keys(plotData),
                            width: 0.5,
                            text: Object.values(plotData).map((value) => {
                                // round everything > 1k
                                return value > 1_000
                                    ? (value / 1_000).toFixed(1) + 'k'
                                    : formatNumber(value, this.translate.currentLang);
                            }),
                            hoverinfo: "none",
                            textinfo: "none",
                            marker: {
                                color: this.defaultPlotOptions.layout.colorway
                            }
                        }];

        let amtOfData = Object.values(plotData).reduce((a, b) => a+b);

        if (plot) {
            plot.layout.yaxis.autorange = false
            // update data
            plot.data = barTrace
            // update title
            plot.show = true
            plot.polling = false;
            plot.layout.yaxis.autorange = true
            plot.layout.title.text = this._t('DASHBOARD.GRAPHS.STATION_STATES')
        } else {
            // create plot
            let chargePointPlot = {
                id: plotId,
                data: barTrace,
                show: amtOfData > 0,
                polling: false,
                layout: {
                    ...this.defaultPlotOptions.layout,
                    title: {
                        text: this._t('DASHBOARD.GRAPHS.STATION_STATES'),
                        y: 0.02,
                    },
                    margin: {b: 55, l: 32, r: 25, t: 25},
                    xaxis: {
                        showticklabels: false,
                        fixedrange: true
                    },
                    yaxis: {
                        color: '#DBEAFE',
                        gridcolor: '#DBEAFE',
                        fixedrange: true
                    },
                    showlegend: false
                },
                config: {
                    ...this.defaultPlotOptions.config,
                },
                onClick: (event: {}) => this._permService.hasPermission('global.stationFilters.quickFilters') ? this.setFilterOnClick(event, 'lastOverallState', 'label') : null
            };

            this.overviewPlots.unshift(chargePointPlot);
        }
    }

    getLowestOverallState(states: lastOverallState[] | string[]): lastOverallState {
        // map all state indexes, if available
        let stateIndexes: number[] = [];
        states.forEach((state: any) => {
            if (state) {
                let stateIndex = this.lastOverallStates.indexOf(state);
                if (stateIndex > -1) { stateIndexes.push(stateIndex) }
            }
        })
        // the higher the index of state, the worse it is
        // we always want to show the worst state
        let worstStateIndex = Math.max(...stateIndexes),
            worstState      = this.lastOverallStates[worstStateIndex];

        // If no matched worstState, return 'No Data'
        return worstState ? worstState : 'No Data';
    }

    // T O O L T I P S
    customTooltip(event: any) {
        this.removeTooltip()

        // get data from event to properly display tooltip on trace
        let eventPoint  = event.points[0],
            x           = event.event.pageX,
            y           = event.event.pageY,
            popup       = document.createElement('div');

        // create tooltip text based on available event data
        let tooltipDate     = eventPoint.x,
            tooltipLabel    = eventPoint.data.name || eventPoint.label || '',
            tooltipValue    = eventPoint.y || eventPoint.value || '',
            suffix: string  = event.yaxes ? event.yaxes[0].ticksuffix : undefined,
            labelSeparator  = ':',
            dateSeparator   = '<br>',
            showLabel       = tooltipLabel && !(tooltipDate === tooltipLabel),
            showDate        = tooltipDate !== undefined;
            
        // check if labels are dates, if so format it properly
        if (typeof(tooltipDate) === 'string') {
            let testDate = new Date(tooltipDate);
            if (testDate.getDate()) {
                tooltipDate = formatDate(testDate, 'MMM d', this.translate.currentLang);
            } else if (this._localizedStateName.isEnumerated(tooltipDate)) {
                tooltipDate = this._localizedStateName.instant(tooltipDate)
            }
        }
        if (typeof(tooltipValue) === 'number' && suffix !== undefined) {
            // if value is number and suffix on y axis add suffix and optionally calc
            if (suffix.toLowerCase() === 'wh') {
                tooltipValue    = Math.round(tooltipValue / 10) / 100
                suffix          = 'k' + suffix
            }

            if (!suffix) {
                tooltipValue = formatNumber(tooltipValue, this.translate.currentLang)
            }

            tooltipValue = `${tooltipValue} ${suffix}`
        }
        
        let tooltipText = ` ${showDate ? tooltipDate : ''}
                            ${showDate ? dateSeparator : ''}
                            ${showLabel ? tooltipLabel : ''}
                            ${showLabel && tooltipLabel && labelSeparator ? labelSeparator : ''} ${tooltipValue}`;
    
        // place popup where it's supposed to be
        popup.innerHTML = tooltipText;
        popup.setAttribute("class", "tooltip-container-absolute to-top");
        popup.style.top = y.toString() + "px";
        popup.style.left = x.toString() + "px";
        // hide first to get clientHeight without weird visible behaviour of tooltip
        popup.style.visibility = "hidden";
        document.body.appendChild(popup);
        // set top depending on tooltip's height (- 10 for tooltip's serration)
        popup.style.top = (y - popup.clientHeight - 10).toString() + "px";
        popup.style.visibility = "visible";
        this.myPopup = popup;
    }
    
    removeTooltip() { if (this.myPopup) { this.myPopup.remove() } }

}
