import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalService } from '../core/app-services/global.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'global-modals',
    template: `
        <!-- I M P R I N T -->
        <app-modal [(open)]="globalService.imprintOpen">
            <ng-container header>
                <p class="subheadline">{{ 'IMPRINT.TITLE' | translate }}</p>
            </ng-container>
            <ng-container body>
                <div [innerHTML]=" 'IMPRINT.CONTENT' | translate">
                </div>
            </ng-container>
        </app-modal>

        <!-- D A T A   P R I V A C Y -->
        <app-modal [(open)]="globalService.privacyOpen">
            <ng-container header>
                <p class="subheadline">{{ 'PRIVACY_POLICY.TITLE' | translate }}</p>
            </ng-container>
            <ng-container body>
                <div class="privacy" [innerHTML]=" 'PRIVACY_POLICY.CONTENT' | translate ">
                </div>
            </ng-container>
        </app-modal>
    `,
    styleUrls: ['./global-modals.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalModalsComponent {

    constructor(
        public globalService: GlobalService,
        public translate: TranslateService
    ) { }

}
