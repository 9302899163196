import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { inAnimation } from 'src/app/core/helpers/animations';
import { KpiBarDataState, KpiBarVariables } from '../kpi-view.component';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber, formatPercent } from '@angular/common';

@Component({
    selector: 'app-kpi-data-bar',
    template: `
    <div class="container pt-48" [@inAnimation]>
        <div 
            *ngIf="kpiBarVariables$ | async as vars"
            class="box-small flex-row align-items-center justify-content-around position-relative"
        >
            <div class="subtitle-cont">
                <div class="subtitle">{{ vars.mode }}</div>
                <div class="date"></div>
            </div>
            <div class="flex-row w-100 align-items-center justify-content-between chargers-row">
                <ng-container *ngFor="let item of dataItems$ | async; let last = last;">
                    <div class="flex-column align-items-center justify-content-center chargers-cont">
                        <div class="flex-column justify-content-center align-items-center chargers-info">
                            <div *ngIf="item.data && item.state === 'ready'; else noDataTemplate" class="value">
                                {{ item.data }}
                            </div>

                            <ng-template #noDataTemplate>
                                <div *ngIf="item.state === 'polling' || item.state === null" class="value">
                                    <app-preloader type="squares"></app-preloader>
                                </div>
                                <div *ngIf="item.state === 'inactive'" class="value">
                                    -
                                </div>
                                <div *ngIf="item.state === 'error'" class="value">
                                    {{ 'APP_ERRORS.NO_DATA' | translate }}
                                </div>
                            </ng-template>

                            <div 
                                class="subline"
                                [class.inactive]="item.state == 'inactive'"
                                [tooltip]="item.tooltip ?? ''"
                                toSide="bottom"
                                size="small"
                                textAlign="left"
                            >
                                {{ item.title }}
                                <div class="info-icon"></div>
                            </div>
                        </div>
                    </div>
                    <div class="divider" *ngIf="!last"></div>
                </ng-container>
            </div>
        </div>
    </div>
    `,
    styleUrls: ['./kpi-data-bar.component.scss'],
    animations: [
        trigger('inAnimation', [
            transition(':enter', [
                useAnimation(inAnimation)
            ])
        ])
    ]
})
export class KpiDataBarComponent {

    // kpi bar variables
    public kpiBarVariables$ = new BehaviorSubject<KpiBarVariables | null>(null);
    @Input() set kpiBarVariables(data: KpiBarVariables | null) {
        this.kpiBarVariables$.next(data)
    };
    // data to display
    private _totalChargers$ = new BehaviorSubject<number | null>(null);
    @Input() set totalChargers(data: number | null) {
        this._totalChargers$.next(data)
    };
    private _chargersAvailable$ = new BehaviorSubject<number | null>(null);
    @Input() set chargersAvailable(data: number | null) {
        this._chargersAvailable$.next(data)
    };
    private _chargersInFailure$ = new BehaviorSubject<number | null>(null);
    @Input() set chargersInFailure(data: number | null) {
        this._chargersInFailure$.next(data)
    };
    // state of the data
    private _totalChargersState$ = new BehaviorSubject<KpiBarDataState>(null);
    @Input() set totalChargersState(data: KpiBarDataState) {
        this._totalChargersState$.next(data)
    }
    private _chargersAvailableState$ = new BehaviorSubject<KpiBarDataState>(null);
    @Input() set chargersAvailableState(data: KpiBarDataState) {
        this._chargersAvailableState$.next(data)
    }
    private _chargersInFailureState$ = new BehaviorSubject<KpiBarDataState>(null);
    @Input() set chargersInFailureState(data: KpiBarDataState) {
        this._chargersInFailureState$.next(data)
    }

    public dataItems$: Observable<any>;

    constructor(
        private _permService: PermissionsService,
        private _translate: TranslateService
    ) {
        const data$ = combineLatest({
            totalChargers: this._totalChargers$,
            chargersAvailable: this._chargersAvailable$,
            chargersInFailure: this._chargersInFailure$
        });
        const states$ = combineLatest({
            totalChargersState: this._totalChargersState$,
            chargersAvailableState: this._chargersAvailableState$,
            chargersInFailureState: this._chargersInFailureState$
        })

        this.dataItems$ = combineLatest({
            data: data$,
            states: states$,
            variables: this.kpiBarVariables$
        }).pipe(
            map(({data, states, variables}) => {
                let accessibleInfo = [];

                if (this._permService.hasPermission('kpiDashboard.dataBars.totalChargers')) {
                    accessibleInfo.push({
                        data: formatNumber(data.totalChargers ?? 0, this._translate.currentLang),
                        state: states.totalChargersState,
                        title: variables?.titles[0],
                        tooltip: variables?.tooltipTexts[0]
                    })
                }

                if (this._permService.hasPermission('kpiDashboard.dataBars.chargersAvailable')) {
                    accessibleInfo.push({
                        data: formatPercent(data.chargersAvailable ?? 0, this._translate.currentLang),
                        state: states.chargersAvailableState,
                        title: variables?.titles[1],
                        tooltip: variables?.tooltipTexts[1]
                    })
                }

                if (this._permService.hasPermission('kpiDashboard.dataBars.chargersInFailure')) {
                    accessibleInfo.push({
                        data: formatNumber(data.chargersInFailure ?? 0, this._translate.currentLang),
                        state: states.chargersInFailureState,
                        title: variables?.titles[2],
                        tooltip: variables?.tooltipTexts[2]
                    })
                }

                return accessibleInfo
            })
        )
    }

}
