import "zone.js/plugins/task-tracking"

import { NgModuleRef, NgZone, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// enable zone.js task tracking
const DEBUG_ZONE: boolean = false;

platformBrowserDynamic().bootstrapModule(AppModule)
    .then((moduleInstance) => {
        if (DEBUG_ZONE) {
            // https://stackoverflow.com/questions/48627074/how-to-track-which-async-tasks-protractor-is-waiting-on
            const win: any = window;
            // Ensure Angular destroys itself on hot reloads.
            if (win["ngRef"]) {
                win["ngRef"].destroy();
            }
            win["ngRef"] = moduleInstance;

            const ngZone = (moduleInstance as NgModuleRef<AppModule>).injector.get(NgZone);
            ngZone.runOutsideAngular(() => {
                setInterval(() => {
                    var taskTrackingZone = (<any>ngZone)._inner.getZoneWith("TaskTrackingZone");
                    if (!taskTrackingZone) {
                        throw new Error("'TaskTrackingZone' zone not found! Have you loaded 'node_modules/zone.js/dist/task-tracking.js'?");
                    }
                    var tasks: any[] = taskTrackingZone._properties.TaskTrackingZone.getTasksFor("macroTask");
                    tasks = [...tasks];
                    if (tasks.length > 0) {
                        console.log("ZONE pending tasks", tasks);
                    }
                }, 1000);
            })
        }
    })
    .catch(err => console.error(err));
