import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, computed, inject } from '@angular/core';
import { SharedModule } from '../shared.module';
import { AsyncPipe, formatNumber } from '@angular/common';
import { ChargingSession, ChargingStation, StationLocations } from 'src/app/core/data-backend/models';
import { TableColumn } from '../table/table.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, distinctUntilChanged, filter, map, of, pairwise, share, startWith, switchMap, tap  } from 'rxjs';
import { AUTO_STYLE, animate, style, transition, trigger } from '@angular/animations';
import { DetailsOverviewService, OverviewService } from 'src/app/core/data-backend/data-services';
import { EnergyMeterValue } from 'src/app/core/data-backend/models/energy-meter-value';
import { eachDayOfInterval } from 'date-fns';
import { ColumnService } from 'src/app/core/helpers/column.service';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';

type ResultRow = {
    connectorId?: number,
    sessionCount?: number | null, // will be null if loading, undefined if not available (" - ")
    chargedAmount?: number | null, // will be null if no start date is set (" - ")
    unit?: string,
    estMeterValue?: number
}

@Component({
    selector: 'evc-meter-value-estimator',
    standalone: true,
    imports: [
        SharedModule,
        ReactiveFormsModule,
        AsyncPipe
    ],
    template: `
        <app-modal
            [open]="open"
            [noBodyPadding]="true"
            [width]="'large'"
            (openChange)="onOpenChange($event)"
        >
            <ng-container header>
                <p class="abstract">{{ 'METER_VALUE_ESTIMATOR.TITLE' | translate }}</p>
            </ng-container>
            <ng-container body>
                <form 
                    class="overflow-hidden"
                    name="estimateMeterValues"
                    [formGroup]="estimateMeterValuesForm"
                >
                    <!-- Station Section -->
                    <div class="form-section">
                        <div class="section-header flex-row">
                            <span class="material-icon">ev_station</span>
                            <p>{{ 'COMMON.STATION.ONE' | translate }}</p>
                        </div>
                        <div class="section-content flex-row">                        
                            <div class="col-6">
                                <div class="group flex-row align-items-center justify-content-start">
                                    <app-select-single
                                        class="flex-grow-1"
                                        [title]="('COMMON.STATION.ONE' | translate) + ' ID'"
                                        [options]="selectOptions"
                                        [activeSelection]="estimateMeterValuesForm.get('stationId')?.value"
                                        (activeSelectionChange)="patchStationId($event)"
                                    />
                                    <button 
                                        class="reset-input"
                                        type="button"
                                        (click)="estimateMeterValuesForm.patchValue({
                                            stationId: null
                                        })"
                                    >
                                        <span class="material-icon">close</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6"></div>
                        </div>
                    </div>
                    <!-- Date Range Section -->
                    <div class="form-section">
                        <div class="section-header flex-row">
                            <span class="material-icon">date_range</span>
                            <p>{{ 'COMMON.DATE_RANGE' | translate }}</p>
                        </div>
                        <div class="section-content flex-row">                        
                            <div class="col-6">
                                <div class="group flex-row align-items-center justify-content-start">
                                    <evc-date-input
                                        [title]="('COMMON.TIMES.FROM' | translate) + ':'"
                                        [value]="estimateMeterValuesForm.get('startDate')?.value ?? null"
                                        [max]="estimateMeterValuesForm.get('endDate')?.value ?? today"
                                        [reset]="'empty'"
                                        (valueChange)="estimateMeterValuesForm.patchValue({
                                            startDate: $event
                                        })"
                                    />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="group flex-row align-items-center justify-content-start">
                                    <evc-date-input
                                        [title]="('COMMON.TIMES.TO' | translate) + ':'"
                                        [value]="estimateMeterValuesForm.get('endDate')?.value ?? null"
                                        [min]="estimateMeterValuesForm.get('startDate')?.value ?? null"
                                        [max]="today"
                                        [disabled]="estimateMeterValuesForm.get('useNow')?.value ?? false"
                                        [reset]="'empty'"
                                        (valueChange)="estimateMeterValuesForm.patchValue({
                                            endDate: $event
                                        })"
                                    />
                                    <div class="checkbox-row flex-row">
                                        <input 
                                            type="checkbox" 
                                            name="now" 
                                            id="now"
                                            formControlName="useNow"
                                        >
                                        <label for="now" class="pl-8 font-weight-500">{{ 'COMMON.TIMES.NOW' | translate }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Consumption Section -->
                    @if (vm$ | async; as vm) {
                        @if (vm.requiredHint) {
                            <div class="form-section">
                                <div class="section-content">        
                                    <div class="hint-row pt-8 flex-row align-items-center justify-content-start">
                                        <span class="material-icon">info</span>
                                        <p class="pl-8">{{ vm.requiredHint }}</p>
                                    </div>
                                </div>
                            </div>
                        } @else {
                            <div class="form-section" [@inOutAnimation]>
                                <div class="section-header flex-row">
                                    <span class="material-icon">electric_meter</span>
                                    <p>{{ 'METER_VALUE_ESTIMATOR.CONSUMPTION' | translate }}</p>
                                </div>
                                <div class="section-content flex-row">                                
                                    <evc-table
                                        [columns]="columns()"
                                        [rows]="vm.rows"
                                        [state]="vm.state"
                                        [resizeable]="false"
                                        [boxShadow]="true"
                                        [strongSeparation]="true"
                                    />
                                </div>
                            </div>
                        }
                    }
                </form>
            </ng-container>
            <ng-container footer>
                <div class="flex-row w-100 justify-content-between">
                    <div class="hint-row pl-32">
                        @if (optionalHint$ | async; as hint) {<p class="hint">{{ hint }}</p>}
                    </div>
                    <button 
                        type="button" 
                        class="done-btn default-button flex-row align-items-center justify-content-center"
                        (click)="onOpenChange(false)"
                    >
                        <span class="material-icon pr-8">done</span>
                        <span class="pr-8">{{ 'COMMON.DONE' | translate }}</span>
                    </button>
                </div>
            </ng-container>
        </app-modal>
    `,
    styleUrl: './meter-value-estimator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ opacity: 0, height: 0 }),
                animate('.25s ease-out', 
                style({ opacity: 1, height: AUTO_STYLE }))
            ]),
            transition(':leave', [
                style({ opacity: 1, height: AUTO_STYLE }),
                animate('.25s ease-out', 
                style({ opacity: 0, height: 0 }))
            ])
        ])
    ]
})

export class MeterValueEstimatorComponent {
    @Input({required: true}) open: boolean | null = false;
    @Output() openChange = new EventEmitter<boolean>();
    // select options
    public selectOptions: {label: string, value: string, metaSearch?: string[]}[] = [];
    @Input({required: true}) set stations(data: StationLocations[] | null) {
        if (!data) return
        this.selectOptions = data.map((stationLocation) => ({
            label: stationLocation.stationId, 
            // add evseIDs to metaSearch to allow for search results to be filtered by evseID
            value: stationLocation.stationId,
            metaSearch: stationLocation.customEvseId
        }))
    };
    // set selected station from parent
    public stationId: string | null = null;
    @Input() set selectedStationId(data: StationLocations['stationId'] | null) {
        // do not update stationId with empty string
        if (data !== null && data.length == 0) return
        this.stationId = data
    }
    public get today() {
        return new Date(new Date().setMinutes(new Date().getMinutes() + 1));
    }
    public estimateMeterValuesForm = new FormGroup({
        stationId: new FormControl<ChargingStation['stationId'] | null>(null),
        startDate: new FormControl<Date | null>(null),
        endDate: new FormControl<Date | null>(null),
        useNow: new FormControl<boolean>(false)
    });

    public columns: Signal<TableColumn[]>;

    // view model for [body]
    public vm$: Observable<{
        state: 'loading' | 'success',
        requiredHint: string | null,
        rows: ResultRow[]
    }>;

    // hint displayed in modal footer
    public optionalHint$: Observable<string | null>;
    private _columnService = inject(ColumnService);

    constructor(
        private _overviewService: OverviewService,
        private _detailsService: DetailsOverviewService,
        public translate: TranslateService
    ) {
        // handles updates of "endDate" when "useNow" is checked, then returns the updated form value
        const formValues$ = this.estimateMeterValuesForm.valueChanges.pipe(
            startWith(this.estimateMeterValuesForm.value),
            pairwise(),
            map(([prev, curr]) => {
                if (!prev.useNow && curr.useNow) {
                    this.estimateMeterValuesForm.patchValue({
                        endDate: new Date()
                    })
                } else if (prev.useNow && !curr.useNow) {
                    this.estimateMeterValuesForm.patchValue({
                        endDate: null
                    })
                }

                return this.estimateMeterValuesForm.value
            }),
            share({connector: () => new ReplaySubject(1)})
        )

        const formTitleMap = () => ({
            stationId: `"${this._t('COMMON.STATION.ONE')} ID"`,
            startDate: `"${this._t('METER_VALUE_ESTIMATOR.FROM_DATE')}"`,
            endDate: `"${this._t('METER_VALUE_ESTIMATOR.TO_DATE')}"`
        })

        const requiredHint$ = combineLatest([
            formValues$.pipe(startWith(this.estimateMeterValuesForm.value)),
            this.translate.onLangChange.pipe(startWith(null))
        ]).pipe(
            map(([formState, newLang]) => {
                const missing = Object.keys(formState)
                    // filter out useNow checkbox (not important for the user hint) and startDate (optional input)
                    .filter((key) => key !== 'useNow' && key !== 'startDate')
                    .map((key) => {
                        const value = formState[key as keyof typeof formState];
                        if (value === null || value === undefined) return formTitleMap()[key as keyof typeof formTitleMap];
                        return null;
                    }).filter((label) => label !== null);

                if (missing.length === 0) return null;
                let missingText = '';
                if (missing.length === 1) {
                    missingText = missing.join('');
                } else {
                    const last = missing.pop();
                    missingText = `${missing.join(', ')} ${this._t('COMMON.AND')} ${last}`;
                }
                return this._t('METER_VALUE_ESTIMATOR.SELECT_CONTENT_TO_START', {content: missingText});
            })
        );

        this.optionalHint$ = formValues$.pipe(
            startWith(this.estimateMeterValuesForm.value),
            map((formState) => {
                return formState.startDate === null && formState.endDate && formState.stationId
                    ? this._t('METER_VALUE_ESTIMATOR.ADDITIONAL_HINT')
                    : null
            })
        );

        // helper to declutter conditions in the following pipes
        const isDefined = (value: any): boolean => value !== null && value !== undefined;

        const startDateResultLoading$ = new BehaviorSubject<boolean>(false);
        const startDateResult$ = formValues$.pipe(
            distinctUntilChanged((prev, current) => prev.stationId === current.stationId && prev.startDate === current.startDate),
            switchMap(({stationId, startDate}) => {
                // force to reset table if no stationId or startDate is set
                if (!isDefined(stationId) || !isDefined(startDate)) {
                    return of(null)
                }
                // only fetch if startDate is given
                startDateResultLoading$.next(true)
                return this._overviewService.getMeterValue({
                    stationId: stationId!,
                    date: startDate!.toISOString()
                }).pipe(
                    tap(_ => startDateResultLoading$.next(false)),
                    startWith([]) // start empty to prevent showing old data
                )
            })
        );

        const endDateResultLoading$ = new BehaviorSubject<boolean>(false);
        const endDateResult$ = formValues$.pipe(
            filter(({stationId, endDate}) => isDefined(stationId) && isDefined(endDate)),
            distinctUntilChanged((prev, current) => prev.stationId === current.stationId && prev.endDate === current.endDate),
            switchMap(({stationId, endDate}) => {
                endDateResultLoading$.next(true)
                return this._overviewService.getMeterValue({
                    stationId: stationId!,
                    date: endDate!.toISOString()
                }).pipe(
                    tap(_ => endDateResultLoading$.next(false)),
                    startWith([]) // start empty to prevent showing old data
                )
            })
        );

        const sessions$ = formValues$.pipe(
            distinctUntilChanged((prev, current) => prev.stationId === current.stationId && prev.startDate === current.startDate && prev.endDate === current.endDate),
            switchMap(({stationId, startDate, endDate}) => {

                // force to reset table if any data is missing
                if (!isDefined(stationId) || !isDefined(startDate) || !isDefined(endDate)) {
                    // undefined will display " - " in table
                    return of(undefined)
                }

                const interval = eachDayOfInterval({
                    start: startDate!,
                    end: endDate!
                }).length - 1;

                return this._detailsService.getChargingSessionsOfChargingStation({
                    stationId: stationId!,
                    date: endDate!.toISOString(),
                    interval: interval,
                    detailed: false
                }).pipe(
                    startWith(null), // start empty to prevent showing old data, null will display as "loading..." in table
                    map((result) => 
                        result
                            ? result.filter((session) => {
                                const sessionStartDate = new Date(session.startDate);
                                // check if startDate is in interval (will include in_progress sessions)
                                return sessionStartDate >= startDate! && sessionStartDate <= endDate!
                            })
                            : null
                    )
                )
            })
        )

        const state$: Observable<'loading' | 'success'> = combineLatest({
            startDateResultLoading: startDateResultLoading$,
            endDateResultLoading: endDateResultLoading$,
            formValues: formValues$
        }).pipe(
            map(({startDateResultLoading, endDateResultLoading, formValues}) => {
                if ((formValues.startDate && startDateResultLoading) || (formValues.endDate && endDateResultLoading)) return 'loading'
                return 'success'
            }),
            startWith('loading' as const)
        );

        const meterValueResults$ = combineLatest([
            startDateResult$.pipe(startWith(null)),
            endDateResult$
        ])

        const rows$ = combineLatest({
            meterValueResults: meterValueResults$,
            sessions: sessions$.pipe(startWith([])),
        }).pipe(
            map(({meterValueResults, sessions}) => {
                const [startDateResult, endDateResult] = meterValueResults;
                if (!endDateResult) return []

                const getConnectorIds = (res: EnergyMeterValue[] | ChargingSession[]) => res.flatMap((x) => x.connectorId);
                const connectorIds = [...new Set([
                    ...(startDateResult ? getConnectorIds(startDateResult) : []),
                    ...getConnectorIds(endDateResult),
                    ...getConnectorIds(sessions ?? [])
                ])];

                // returns null if loading, undefined if not available or number if available
                const sessionsValue = (sessions: ChargingSession[] | null | undefined) => sessions === null ? null : sessions === undefined ? undefined : sessions.length;

                let rows: ResultRow[]   = [],
                    chargeSum: number   = 0;

                // only prepare rows for connectors if startDate is set, else we only show the estimated meter value for the whole station
                if (startDateResult) {
                    connectorIds.forEach((connectorId) => {
                        const startRes = startDateResult && startDateResult.find((res) => res.connectorId === connectorId);
                        const endRes = endDateResult.find((res) => res.connectorId === connectorId);
                        const connectorSessions = sessions?.filter((session) => session.connectorId === connectorId);
                        // get each result in Wh, calc from kWh where needed, 0 if no result
                        const startValue = startRes && startRes.unit == 'Wh' ? startRes.value : startRes ? startRes.value * 1000 : 0;
                        const endValue = endRes && endRes.unit == 'Wh' ? endRes.value : endRes ? endRes.value * 1000 : 0;
                        // get charged amount for this connector
                        let connectorWh = endValue - startValue;
                        // fallback to 0 instead of negative value, e.g. startDate does not have data of this connector but endDate has
                        connectorWh = connectorWh >= 0 ? connectorWh : 0;
                        chargeSum   += connectorWh;

                        rows.push({
                            connectorId,
                            chargedAmount: startDateResult ? +(connectorWh / 1000).toFixed(2) : null, // null if no startDate is set
                            unit: 'kWh',
                            sessionCount: sessionsValue(connectorSessions)
                        });
                    })
                }

                // station row
                rows.push({
                    chargedAmount: startDateResult ? +(chargeSum / 1000).toFixed(2) : null, // null if no startDate is set
                    unit: 'kWh',
                    sessionCount: sessionsValue(sessions),
                    estMeterValue: +(endDateResult.reduce((prev, curr) => prev + curr.value, 0)/ 1000).toFixed(2),
                })

                return rows
            })
        )

        this.vm$ = combineLatest({
            state: state$,
            requiredHint: requiredHint$,
            rows: rows$.pipe(startWith([]))
        });

        const newLang = toSignal(this.translate.onLangChange);
        // update columns on language change
        this.columns = computed(() => {
            const lang = newLang();
            return [
                {
                    id: 0,
                    title: this._t('COMMON.CONNECTOR.ONE'),
                    keys: [{
                        key: 'connectorId',
                        title: this._t('COMMON.CONNECTOR.ONE'),
                        type: 'number'
                    }],
                    config: {
                        noSearch: true,
                        noFilter: true,
                        noSort: true,
                        noMinWidth: true,
                        defaultWidth: 160
                    },
                    renderCell: (attr) => {
                        // return station icon for undefined connectorId
                        // (we cant assign the station row to connectorId 0, as this would interfere with default sorting of the table)
                        if (attr[0] === undefined) return this._columnService.getConnectorStyling([0])
                        return this._columnService.getConnectorStyling(attr)
                    },
                },
                {
                    id: 1,
                    title: this._t('COMMON.SESSION.OTHER'),
                    keys: [{
                        key: 'sessionCount',
                        title: 'Sessions Count',
                        type: 'number'
                    }],
                    config: {
                        noFilter: true,
                        noSort: true,
                        noSearch: true,
                        squished: 'right'
                    },
                    renderCell: (attr) => {
                        const [ sessionCount ] = attr;
                        if (sessionCount === null)      return `<p class="subline text-right">${this._t('COMMON.LOADING.DEFAULT')}...</p>`;
                        if (sessionCount === undefined) return '<p class="subline text-right"> - </p>';
                        return `<p class="text-right">${formatNumber(sessionCount, this.translate.currentLang)}</p>`;
                    },
                },
                {
                    id: 2,
                    title: this._t('METER_VALUE_ESTIMATOR.CHARGE_SUM'),
                    keys: [
                        {
                            key: 'chargedAmount',
                            title: 'Charged Amount',
                            type: 'number'
                        },
                        {
                            key: 'unit',
                            title: 'Unit',
                            type: 'string',
                            hidden: true
                        }
                    ],
                    config: {
                        noFilter: true,
                        noSort: true,
                        noSearch: true,
                        squished: 'right'
                    },
                    renderCell: (attr) => {
                        const [chargedAmount, unit] = attr;
                        if (chargedAmount === undefined || chargedAmount === null) return '<p class="subline text-right"> - </p>';
                        return `<p class="text-right">${formatNumber(chargedAmount, this.translate.currentLang, '1.2-2')} ${unit}</p>`;
                    },
                },
                {
                    id: 3,
                    title: this._t('METER_VALUE_ESTIMATOR.ESTIMATED_METER_VALUE'),
                    keys: [
                        {
                            key: 'estMeterValue',
                            title: 'Estimated Meter Value',
                            type: 'number'
                        },
                        {
                            key: 'unit',
                            title: 'Unit',
                            type: 'string',
                            hidden: true
                        }
                    ],
                    config: {
                        noFilter: true,
                        noSort: true,
                        noSearch: true,
                        squished: 'right'
                    },
                    renderCell: (attr) => {
                        const [estMeterValue, unit] = attr;
                        if (estMeterValue === undefined || estMeterValue === null) return '<p class="subline text-right"> - </p>'
                        return `<p class="text-right">${formatNumber(estMeterValue, this.translate.currentLang, '1.2-2')} ${unit}</p>`;
                    }
                }
            ];
        })
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this.translate.instant(path, interpolateParams)
    }

    public onOpenChange(open: boolean) {
        if (open) {
            // on open - set stationId
            this.estimateMeterValuesForm.patchValue({
                stationId: this.stationId
            })
        } else {
            // on close - emit and reset form
            this.openChange.emit(open)
            this.estimateMeterValuesForm.reset()
        }
    }

    public patchStationId(event: string | number) {
        const asString = typeof(event) == 'string' ? event : JSON.stringify(event);
        this.estimateMeterValuesForm.patchValue({
            stationId: asString
        })
    }
}
