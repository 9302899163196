import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpRequest, HttpHandler,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { appRepository } from './core/stores/app.repository';
import { tryRefresh } from './core/app-services/app-auth.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(
        private _appRepo: appRepository,
        private _oAuthService: OAuthService
    ) { }

    // requests will be retried a maximum of 3 times before an error is thrown
    // unless the error status is 401, then the token will be refreshed and the request retried with new Auth header
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // max retries for requests
        // useful if 401 error is thrown because of missing permissions, not because of expired token
        let maxRetries = 3;
        
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (maxRetries-- > 0) {
                    if (error.status !== 401) {
                        // Delay retry for all errors except 401
                        return of(request).pipe(delay(1000), switchMap(req => next.handle(req)));
                    } else {
                        // if unauthorized, check detail if customer needs to be set
                        if (error.error.detail && error.error.detail === "Active customer not set.") {
                            // set global customer state to "not set", forcing to show login screen with customer selection
                            this._appRepo.updateSelectedCustomer(null)
                        }
                        // try token refresh
                        return from(tryRefresh(this._oAuthService, this._appRepo)).pipe(
                            // retry the failed request
                            switchMap((newToken) => {
                                // clone request, set new auth header
                                const authReq = request.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${newToken}`
                                    }
                                });
                                return next.handle(authReq);
                            })
                        );
                    }
                } else {
                    return throwError(() => new Error(`Maximum request retries (${maxRetries}) exceeded`));
                }
            })
        );
    }
}
