import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, BehaviorSubject, skip } from 'rxjs';
import { notificationsRepository } from 'src/app/core/stores/notifications.repository';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-notifications-filters',
    standalone: true,
    imports: [
        AsyncPipe,
        SharedModule
    ],
    template: `
        <div class="notifications-filters-cont">
            <div class="content">
                <div class="title">{{ 'NOTIFICATIONS_VIEW.YOUR_NOTIFICATIONS' | translate }}</div>
                <div class="filter-options">
                    <button
                        class="notifications-filter-btn mr-32"
                        [class.active]="repo.activeOnly$ | async"
                        (click)="
                            activeOnly = !activeOnly;
                            repo.updateActiveOnly(activeOnly)
                        "
                    >{{ 'NOTIFICATIONS_VIEW.ACTIVATED_ONLY' | translate }}</button>
                    <input
                        [value]="repo.search$ | async"
                        type="text"
                        [placeholder]="'COMMON.SEARCH' | translate"
                        class="notifications-search-input"
                        (input)="handleSearch($event)"
                    >
                    <button
                        class="submit-search"
                        (click)="searchQuery$.next('')"
                    >
                        <span class="material-icon">
                            @if (repo.search$ | async) {clear}
                            @else {search}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    `,
    styleUrl: './notifications-filters.component.scss'
})
export class NotificationsFiltersComponent {
    public activeOnly = false;
    public searchQuery$ = new BehaviorSubject<string>('');

    constructor(
        public repo: notificationsRepository,
    ) {
        // throttle search input
        this.searchQuery$.pipe(
            takeUntilDestroyed(),
            skip(1),
            debounceTime(150),
            distinctUntilChanged(),
        ).subscribe((value) => {
            this.repo.updateSearch(value)
        });
    }

    handleSearch(event: Event) {
        let value = (event.target as HTMLInputElement).value;
        this.searchQuery$.next(value);
    }
}
