import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, take, tap } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { CoreModule } from 'src/app/core/core.module';
import { NotificationDataService } from 'src/app/core/data-backend/data-services';
import { Notification, Tenant } from 'src/app/core/data-backend/models';
import { notificationsRepository } from 'src/app/core/stores/notifications.repository';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotificationsMaskComponent } from '../notifications-mask/notifications-mask.component';

@Component({
    selector: 'app-notifications-create',
    standalone: true,
    imports: [
        CoreModule,
        SharedModule,
        AsyncPipe,
        NotificationsMaskComponent
    ],
    template: `
        <evc-collapsible
            [active]="true"
            [parentClasses]="['details-header-collapsible']"
            [collapsed]="(repo.collapsed$ | async) ?? true"
            (collapsedChange)="repo.setCollapsed($event)"
        >
            <ng-container header>
                <div class="title">{{ 'NOTIFICATIONS_VIEW.CREATE_NOTIFICATION' | translate }}</div>
            </ng-container>

            <ng-container body>
                <div class="wrapper">
                    <app-notifications-mask
                        [mode]="'create'"
                        [userTenants]="userTenants"
                        [otherTenants]="otherTenants"
                        [state]="state$ | async"
                        (submitNotification)="createNotification($event)"
                    ></app-notifications-mask>
                </div>
            </ng-container>
        </evc-collapsible>
    `,
    styleUrls: ['./notifications-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsCreateComponent {
    public state$ = new BehaviorSubject<'loading' | 'error' | 'success' | null>(null);
    @Input('state') set state(state: 'loading' | 'error' | 'success' | null) {
        this.state$.next(state);
    }
    @Input() userTenants: Tenant[] | null = null;
    @Input() otherTenants: Tenant[] | null = null;

    @Output('getNotificationData') getNotificationData = new EventEmitter<void>();

    constructor(
        public repo: notificationsRepository,
        public notificationDataService: NotificationDataService,
        public notificationService: NotificationService
    ) {}

    createNotification(notification: Notification) {
        this.notificationDataService.addNotification({
            body: notification
        }).pipe(take(1)).subscribe({
            error: (_) => {
                this.notificationService.showLocalizedError('NOTIFICATIONS_VIEW.INFO.CREATE_ERROR', 'COMMON.ERROR.ONE')
            },
            next: (_) => {
                this.notificationService.showLocalizedSuccess('NOTIFICATIONS_VIEW.INFO.CREATE_SUCCESS')
                // fetch data
                this.getNotificationData.emit();
                // close modal
                this.repo.setCollapsed(true);
            }
        })
    }
}
