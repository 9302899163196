import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { BehaviorSubject, Observable, ReplaySubject, catchError, combineLatest, map, of, share, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { TableColumn } from '../shared/table/table.component';
import { ColumnService } from '../core/helpers/column.service';
import { Notification, Tenant } from '../core/data-backend/models';
import { NotificationDataService, TenantService } from '../core/data-backend/data-services';
import { NotificationService } from '../core/app-services';
import { notificationsRepository } from '../core/stores/notifications.repository';
import { AsyncPipe } from '@angular/common';
import { NotificationsFiltersComponent } from './notifications-filters/notifications-filters.component';
import { NotificationsCreateComponent } from './notifications-create/notifications-create.component';
import { PermissionsService } from '../core/app-services/permissions.service';
import { CoreModule } from '../core/core.module';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NotificationsModalComponent } from './notifications-modal/notifications-modal.component';
import { appRepository } from '../core/stores/app.repository';
import { TranslateService } from '@ngx-translate/core';
import { BulkSelectAction } from '../shared/table-bulk-panel/table-bulk-panel.component';

@Component({
    selector: 'app-notifications-view',
    standalone: true,
    imports: [
        SharedModule,
        CoreModule,
        AsyncPipe,
        NotificationsFiltersComponent,
        NotificationsCreateComponent,
        NotificationsModalComponent
    ],
    template: `
    <div 
        *evcHasPermissions="'global.manageNotifications'"
        class="position-relative"
    >
        @if (tenants$ | async; as tenants) {
            <app-notifications-create
                [userTenants]="tenants.userTenants"
                [otherTenants]="tenants.otherTenants"
                [state]="dataState$ | async"
                (getNotificationData)="getNotificationData()"
            ></app-notifications-create>
        }
    </div>
    <div class="container pt-32">
        <div class="position-relative">
            <app-notifications-filters
            ></app-notifications-filters>
        </div>
    </div>
    <div class="container pt-16 pb-32">
        <div class="position-relative">
            <evc-table
                [columns]="columns()"
                [rows]="tableContents$ | async"
                [resizeable]="false"
                [scrollable]="true"
                [strongSeparation]="false"
                [perPage]="10"
                [backgroundColorVar]="'background.base'"
                [bulkActions]="bulkActions()"
                [state]="dataState$ | async"
                [defaultSortByKey]="'from_date'"
                [defaultSortDirection]="'desc'"
                [searchQuery]="repo.search$ | async"
                [(currentPage)]="currentPage"
                (onBulkAction)="handleBulkActions($event)"
                (onCellAction)="handleCellActions($event)"
            >
            </evc-table>
        </div>
    </div>
    @if (updateModalOpen) {
        @if (tenants$ | async; as tenants) {
            <app-notifications-modal
                [(open)]="updateModalOpen"
                [notification]="updateNotification$ | async"
                [userTenants]="tenants.userTenants"
                [otherTenants]="tenants.otherTenants"
                [state]="dataState$ | async"
                (getNotificationData)="getNotificationData()"
            ></app-notifications-modal>
        }
    }
    `,
    styleUrl: './notifications-view.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsViewComponent {

    baseColumns = (): TableColumn[] => [
        {
            id: 0,
            title: this._t('NOTIFICATIONS_VIEW.COLUMNS.ACTIVATED'),
            keys: [{
                title: this._t('NOTIFICATIONS_VIEW.COLUMNS.ACTIVATED'),
                key: 'active',
                type: 'boolean'
            }],
            config: {
                noMinWidth: true,
                noFilter: true,
                noSearch: true,
                squished: 'left',
                tooltip: {
                    type: 'activated',
                    size: 'small',
                    textAlign: 'left'
                }
            },
            renderCell: (attr: any[]) => {
                return this.columnService.getActivatedStyling(attr);
            }
        },
        {
            id: 1,
            title: this._t('NOTIFICATIONS_VIEW.FORM.TITLE'),
            keys: [{
                title: this._t('NOTIFICATIONS_VIEW.FORM.TITLE'),
                key: 'title',
                type: 'string'
            }],
            config: {
                squished: 'left'
            },
            renderCell: (attr: any[]) => {
                if (attr[0] == null) return '-';
                return `<strong>${attr[0]}</strong>`
            }
        },
        {
            id: 2,
            title: this._t('NOTIFICATIONS_VIEW.FORM.DESCRIPTION'),
            keys: [{
                title: this._t('NOTIFICATIONS_VIEW.FORM.DESCRIPTION'),
                key: 'description',
                type: 'string'
            }],
            config: {
                squished: 'left',
                tooltip: {
                    type: 'max-100',
                    size: 'small',
                    textAlign: 'left',
                    toSide: 'top',
                    width: 500,
                }
            }
        },
        {
            id: 3,
            title: this._t('NOTIFICATIONS_VIEW.COLUMNS.CATEGORY'),
            keys: [{
                title: this._t('NOTIFICATIONS_VIEW.COLUMNS.CATEGORY'),
                key: 'category',
                type: 'string'
            }],
            config: {
                noSearch: true,
                noFilter: true,
                noMinWidth: true,
                squished: 'left',
                tooltip: {
                    type: 'category',
                    size: 'small',
                    textAlign: 'left'
                }
            },
            renderCell: (attr: any[]) => {
                return this.columnService.getCategoryStyling(attr);
            }
        },
        {
            id: 4,
            title: this._t('COMMON.TIMES.FROM'),
            keys: [{
                title: this._t('COMMON.TIMES.FROM'),
                key: 'from_date',
                type: 'date'
            }],
            config: {
                noMinWidth: true,
                noSearch: true,
                squished: 'left'
            },
            renderCell: (attr: any[]) => {
                return this.columnService.getFromStyling(attr);
            }
        },
        {
            id: 5,
            title: this._t('COMMON.TIMES.TO'),
            keys: [{
                title: this._t('COMMON.TIMES.TO'),
                key: 'to_date',
                type: 'date'
            }],
            config: {
                noMinWidth: true,
                noSearch: true,
                squished: 'left'
            },
            renderCell: (attr: any[]) => {
                return this.columnService.getToStyling(attr);
            }
        },
        {
            id: 6,
            title: this._t('NOTIFICATIONS_VIEW.FORM.PERSISTENT'),
            keys: [{
                title: this._t('NOTIFICATIONS_VIEW.FORM.PERSISTENT'),
                key: 'persistent',
                type: 'boolean'
            }],
            config: {
                noMinWidth: true,
                noFilter: true,
                noSearch: true,
                squished: 'left',
                tooltip: {
                    type: 'persistent',
                    size: 'small',
                    textAlign: 'left'
                }
            },
            renderCell: (attr: any[]) => {
                return this.columnService.getPersistentStyling(attr);
            }
        },
        {
            id: 7,
            title: this._t('MANAGE_USERS_VIEW.INVITE_USERS.TENANTS.TITLE'),
            keys: [{
                title: this._t('MANAGE_USERS_VIEW.INVITE_USERS.TENANTS.TITLE'),
                key: 'tenants',
                type: 'array'
            }, {
                title: 'User Tenants',
                key: 'userTenants',
                type: 'array',
                hidden: true
            }],
            config: {
                noMinWidth: true,
                noFilter: true,
                squished: 'left'
            },
            renderCell: (attr: any[]) => {
                return this.columnService.getNotificationsTenantGroupsStyling(attr);
            }
        }
    ];

    columns: Signal<TableColumn[]>;

    bulkActions: Signal<BulkSelectAction[]>;

    // content that will be displayed in the notifications table
    tableContents$ = new BehaviorSubject<(Notification & {userTenants: string[]})[]>([]);

    // notifications fetched from data backend
    notifications$ = new BehaviorSubject<Notification[]>([]);

    // tenants that will be listed in the create panel
    public tenants$: Observable<{
        userTenants: Tenant[],
        otherTenants: Tenant[]
    } | null>;

    // state of the data
    dataState$ = new BehaviorSubject<'loading' | 'error' | 'success' | null>(null);

    // current page of the table
    currentPage: number = 1;

    // whether update modal is open or not
    updateModalOpen: boolean = false;

    // notification that should be updated
    updateNotification$ = new BehaviorSubject<Notification | null>(null);

    constructor(
        public repo: notificationsRepository,
        public notificationDataService: NotificationDataService,
        public notificationService: NotificationService,
        public columnService: ColumnService,
        public permService: PermissionsService,
        private _appRepo: appRepository,
        private _tenantService: TenantService,
        private _translate: TranslateService
    ) {
        // fetch notifications from backend
        this.getNotificationData();

        // prepare notifications & handling interactions
        combineLatest([
            this.notifications$,
            this.repo.activeOnly$,
            this.repo.search$
        ]).pipe(
            takeUntilDestroyed(),
            withLatestFrom(this._appRepo.currentUser$),
            map(([[notifications, activeOnly, search], user]) => {
                // filter out deactivated notifications if 'active only'
                if (activeOnly) notifications = notifications.filter((notification) => notification.active);
                // sort notifications tenants lists
                notifications.forEach((notification) => notification.tenants.sort());
                // reset table page
                this.currentPage = 1;
                // update table data
                this.tableContents$.next(notifications.map((notification) => {
                    return {
                        ...notification,
                        userTenants: user?.tenants ?? []
                    }
                }));
            })
        ).subscribe();

        // update tenants based on user permissions and current user
        this.tenants$ = combineLatest({
            access: this.permService.ofPermission('global.manageNotifications'),
            user: this._appRepo.currentUser$
        }).pipe(
            switchMap(({access, user}) => {
                if (!access || !user) return of(null);
                return this._tenantService.getTenants().pipe(
                    map((tenants) => {
                        let userTenants: Tenant[] = [],
                            otherTenants: Tenant[] = [];
                        
                        for (let tenant of tenants) {
                            if (user.tenants.includes(tenant.identifier)) {
                                userTenants.push(tenant)
                            } else {
                                otherTenants.push(tenant)
                            }
                        }

                        return {userTenants, otherTenants}
                    })
                )
            }),
            startWith({userTenants: [], otherTenants: []}),
            share({connector: () => new ReplaySubject(1)})
        )

        // listen to language changes
        const newLang = toSignal(this._translate.onLangChange);
        this.columns = computed(() => {
            newLang();

            return this.permService.hasPermission('global.manageNotifications') ?
                [
                    ...this.baseColumns(),
                    {
                        id: 8,
                        title: '',
                        config: {
                            noSearch: true,
                            noFilter: true,
                            noSort: true,
                            defaultWidth: 60,
                            noMinWidth: true,
                            sticky: 'right'
                        },
                        actions: [
                            {
                                id: 'edit',
                                title: this._t('NOTIFICATIONS_VIEW.EDIT_NOTIFICATION'),
                                icon: 'edit'
                            }
                        ]
                    }
                ] : [...this.baseColumns()];
        });

        this.bulkActions = computed(() => {
            newLang();

            return this.permService.hasPermission('global.manageNotifications') ? [{
                id: 'deactivateNotification',
                title: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DEACTIVATE.TITLE'),
                icon: 'notifications_off',
                conditions: {
                    show: (selectedIds) => selectedIds.some(id => this.tableContents$.value[Number(id)]?.active)
                },
                confirmation: {
                    title: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DEACTIVATE.CONFIRMATION.TITLE'),
                    text: (selectedIds) => selectedIds.length == 1 
                        ? this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DEACTIVATE.CONFIRMATION.TEXT.ONE', {n: selectedIds.length})
                        : this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DEACTIVATE.CONFIRMATION.TEXT.OTHER', {n: selectedIds.length}),
                    button: {
                        text: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DEACTIVATE.CONFIRMATION.BUTTON'),
                        icon: 'notifications_off'
                    }
                }
            }, {
                id: 'activateNotification',
                title: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.ACTIVATE.TITLE'),
                icon: 'notifications',
                conditions: {
                    show: (selectedIds) => selectedIds.some(id => !this.tableContents$.value[Number(id)]?.active)
                },
                confirmation: {
                    title: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.ACTIVATE.CONFIRMATION.TITLE'),
                    text: (selectedIds) => selectedIds.length == 1 
                        ? this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.ACTIVATE.CONFIRMATION.TEXT.ONE', {n: selectedIds.length})
                        : this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.ACTIVATE.CONFIRMATION.TEXT.OTHER', {n: selectedIds.length}),
                    button: {
                        text: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.ACTIVATE.CONFIRMATION.BUTTON'),
                        icon: 'notifications'
                    }
                }
            }, {
                id: 'deleteNotification',
                title: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DELETE.TITLE'),
                icon: 'delete',
                confirmation: {
                    title: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DELETE.CONFIRMATION.TITLE'),
                    text: (selectedIds) => selectedIds.length == 1 
                        ? this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DELETE.CONFIRMATION.TEXT.ONE', {n: selectedIds.length})
                        : this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DELETE.CONFIRMATION.TEXT.OTHER', {n: selectedIds.length}),
                    button: {
                        text: this._t('NOTIFICATIONS_VIEW.BULK_ACTIONS.DELETE.CONFIRMATION.BUTTON'),
                        icon: 'delete'
                    }
                }
            }] : [];
        })
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    getNotificationData() {
        this.dataState$.next('loading');

        this.notificationDataService.getNotifications().pipe(
            take(1),
            catchError((_) => {
                this.dataState$.next('error');
                this.notificationService.showError(
                    this._t('NOTIFICATIONS_VIEW.INFO.RETRIEVE_NOTIFICATIONS_ERROR'),
                    this._t('COMMON.ERROR.ONE')
                );
                return of([]);
            }),
        ).subscribe({
            next: (notifications) => {
                this.dataState$.next('success');
                this.notifications$.next(notifications);
            }
        });
    }

    public handleCellActions(cellAction: {actionId: string, row: number}) {
        this.tableContents$.pipe(
            take(1),
            tap((notifications: Notification[]) => {
                let notification = notifications[cellAction.row];
                if (cellAction.actionId == 'edit') {
                    this.updateModalOpen = true
                    this.updateNotification$.next(
                        // pass a deep copy to prevent overwriting
                        JSON.parse(JSON.stringify(notification)
                    ))
                }
            })
        ).subscribe()
    }

    handleBulkActions(bulkaction: {action: string, rows: number[]}) {
        this.tableContents$.pipe(
            take(1),
            tap(((notifications: Notification[]) => {
                // get selected notifications
                notifications = bulkaction.rows
                    .filter((row) => row >= 0 && row < notifications.length)
                    .map((row) => notifications[row]);
                if (bulkaction.action == 'deactivateNotification') {
                    // deactivate notifications
                    notifications.forEach(notification => notification.active = false);
                    this.notificationDataService.updateNotifications({body: {notifications: notifications}}).pipe(
                        take(1),
                        tap((_) => {
                            this.notificationService.showSuccess(
                                this._t('NOTIFICATIONS_VIEW.INFO.DEACTIVATE_SUCCESS')
                            )
                            this.getNotificationData();
                        }),
                        catchError(_ => {
                            this.notificationService.showError(
                                this._t('NOTIFICATIONS_VIEW.INFO.DEACTIVATE_ERROR'),
                                this._t('COMMON.ERROR.ONE')
                            );
                            return of([]);
                        }),
                    ).subscribe();
                }
                if (bulkaction.action == 'activateNotification') {
                    // activate notifications
                    notifications.forEach(notification => notification.active = true);
                    this.notificationDataService.updateNotifications({body: {notifications: notifications}}).pipe(
                        take(1),
                        tap((_) => {
                            this.notificationService.showSuccess(
                                this._t('NOTIFICATIONS_VIEW.INFO.ACTIVATE_SUCCESS')
                            )
                            this.getNotificationData();
                        }),
                        catchError(_ => {
                            this.notificationService.showError(
                                this._t('NOTIFICATIONS_VIEW.INFO.ACTIVATE_ERROR'),
                                this._t('COMMON.ERROR.ONE')
                            );
                            return of([]);
                        }),
                    ).subscribe();
                }
                if (bulkaction.action == 'deleteNotification') {
                    // delete notifications
                    let idsToDelete = notifications.map(notification => notification.notificationId);
                    this.notificationDataService.deleteNotifications({notificationIds: idsToDelete}).pipe(
                        take(1),
                        tap((_) => {
                            this.notificationService.showSuccess(
                                this._t('NOTIFICATIONS_VIEW.INFO.DELETE_SUCCESS')
                            )
                            this.getNotificationData();
                        }),
                        catchError(_ => {
                            this.notificationService.showError(
                                this._t('NOTIFICATIONS_VIEW.INFO.DELETE_ERROR'),
                                this._t('COMMON.ERROR.ONE')
                            );
                            return of([]);
                        }),
                    ).subscribe();
                }
            }))
        ).subscribe();
    }
}
